import React, { FC, useContext } from 'react';
import { isBrowser } from 'utils/browser';
import GatsbyImage from 'common/GatsbyImage';
import { DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';
import { CartContext } from 'context/CartContextProvider/CartContextProvider';
import CheckoutItem from './CheckoutItem';
import { ICheckout } from './model';
import './Checkout.scss';

const Checkout: FC<ICheckout> = ({
  itemsLabel,
  shippingLabel,
  subtotalLabel,
  freeShippingLabel,
  disclaimer,
  helpLabel,
  linkContact,
  linkCheckout,
  cards,
  shopifyLogo,
  secureLabel,
}) => {
  const {
    totalPrice,
    currencieLabel,
    shopUrl,
    shippingCost,
    oldTotalPrice,
    cartItemsQuantity,
    isLoading,
  } = useContext(CartContext);
  const origin = isBrowser() ? window.location.origin : null;

  return (
    <div className="checkout" data-testid="checkout">
      <ul className="checkout__prices">
        <CheckoutItem
          itemLabel={itemsLabel}
          currencieLabel={currencieLabel}
          itemPrice={totalPrice}
          oldPrice={oldTotalPrice}
        />
        <CheckoutItem
          itemLabel={shippingLabel}
          currencieLabel={currencieLabel}
          itemPrice={shippingCost}
          alternativeLabel={freeShippingLabel}
        />
        <CheckoutItem
          itemLabel={subtotalLabel}
          currencieLabel={currencieLabel}
          itemPrice={totalPrice}
        />
      </ul>
      {linkCheckout?.length ? (
        <Button
          isLoading={isLoading}
          disabled={!cartItemsQuantity || isLoading}
          variant="secondary"
          classes="checkout__btn"
          to={cartItemsQuantity ? `${shopUrl}&redirectURL=${origin}` : null}
          target={linkCheckout[0].properties.link[0].target}
          ariaLabel={linkCheckout[0].properties.ariaLabel}
        >
          {linkCheckout[0].properties.link[0].name}
        </Button>
      ) : null}
      {disclaimer ? (
        <DangerouslySetInnerHtml className="checkout__disclaimer" html={disclaimer} />
      ) : null}
      {cards?.length ? (
        <div className="checkout__cards">
          {cards.map(({ properties: { image, imageAlt } }) => (
            <div key={imageAlt} className="checkout__cards-item">
              <GatsbyImage
                image={image}
                alt={imageAlt}
                className="checkout__cards-image"
                objectFit="contain"
              />
            </div>
          ))}
        </div>
      ) : null}
      {shopifyLogo?.length ? (
        <div className="checkout__secure">
          <div className="checkout__secure-logo">
            <GatsbyImage
              image={shopifyLogo[0].properties.image}
              alt={shopifyLogo[0].properties.imageAlt}
              className="checkout__secure-logo-img"
              objectFit="contain"
            />
          </div>
          {secureLabel ? (
            <DangerouslySetInnerHtml className="checkout__secure-label" html={secureLabel} />
          ) : null}
        </div>
      ) : null}
      {helpLabel ? <div className="checkout__help">{helpLabel}</div> : null}
      {linkContact?.length ? (
        <div className="checkout__contact">
          <Button
            variant="secondary-link"
            classes="checkout__contact-btn"
            iconSuffix="chevron-right"
            to={linkContact[0].properties.link[0].url}
            ariaLabel={linkContact[0].properties.ariaLabel}
          >
            {linkContact[0].properties.link[0].name}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default Checkout;
