import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'gatsby-theme-husky/src/layout/Layout';
import { CartpageTypes } from '@shared/types/umbraco/content/cartPage';
import BodyRenderer from 'gatsby-theme-husky/src/common/BodyRenderer';
import SignUp from 'components/SignUp';
import { Container, DangerouslySetInnerHtml } from 'layout';
import GridList from 'components/GridList';
import BreadCrumbs from 'common/BreadCrumbs';
import Cart from 'common/Cart';
import './CartPage.scss';

const elements = {
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
  'Image Text Grid': ({ properties }, keyId) => <GridList key={keyId} {...properties} />,
};

const CartPage: FC<CartpageTypes.IProperties> = ({
  pageContext: { breadCrumbs, link, searchUrl },
  data: {
    page: {
      nodes: [
        {
          defaultCompositions,
          body,
          seoMetaKeywords,
          seoMetaTitle,
          seoMetaTitleTemplate,
          seoMetaDescription,
          seoExternalHreflangs,
          seoNoIndex,
          seoCanonicalUrl,
          itemsLabel,
          topMessage,
          subtotalLabel,
          shippingLabel,
          emptyCartIconMessage,
          freeShippingLabel,
          disclaimer,
          helpLabel,
          linkContact,
          linkCheckout,
          cards,
          shopifyLogo,
          secureLabel,
          alternateUrls,
          ogImageUrl,
        },
      ],
    },
    allBundle,
    allProduct,
  },
}) => {
  const { lang, isShopify } = defaultCompositions.siteSettings;
  const { singupFormBaner, purchaseSettings, waiSettings } = defaultCompositions;
  const { cartPath } = defaultCompositions?.header;

  const allCartProducts = { nodes: [...allProduct?.nodes, ...allBundle?.nodes] };

  return (
    <Layout
      {...{
        ...defaultCompositions,
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaTitleTemplate,
        seoNoIndex,
        seoMetaDescription,
        seoExternalHreflangs,
        seoCanonicalUrl,
        purchaseSettings,
        skipText: waiSettings?.skipText,
        link,
        searchUrl,
        alternateUrls,
        imageUrl: ogImageUrl,
      }}
    >
      <h1 className="visual-hidden">{seoMetaTitle}</h1>
      {topMessage ? (
        <DangerouslySetInnerHtml className="cart-page__top-message" html={topMessage} />
      ) : null}
      <Container fluid>
        <div className="cart-page__body">
          {breadCrumbs?.length ? <BreadCrumbs data={breadCrumbs} theme="light" /> : null}
          <Cart
            lang={lang}
            isShopify={isShopify}
            cartPath={cartPath}
            cartProductsData={allCartProducts}
            itemsLabel={itemsLabel}
            shippingLabel={shippingLabel}
            secureLabel={secureLabel}
            subtotalLabel={subtotalLabel}
            shopifyLogo={shopifyLogo}
            disclaimer={disclaimer}
            helpLabel={helpLabel}
            cards={cards}
            linkContact={linkContact}
            linkCheckout={linkCheckout}
            emptyCartIconMessage={emptyCartIconMessage}
            freeShippingLabel={freeShippingLabel}
          />
        </div>
      </Container>
      <BodyRenderer
        bodyData={body}
        bodyStructure={elements}
        bodyItemProps={{ ...{ singupFormBaner } }}
      />
    </Layout>
  );
};
export const query = graphql`
  query cartQuery($link: String) {
    page: allCart(filter: { link: { eq: $link } }) {
      nodes {
        seoMetaTitle
        seoMetaTitleTemplate
        seoMetaDescription
        seoMetaKeywords
        seoNoIndex
        seoExternalHreflangs {
          key
          value
        }
        seoCanonicalUrl {
          url
        }
        alternateUrls {
          lang
          url
          path
        }
        ogImageUrl
        defaultCompositions {
          purchaseSettings {
            ...FragmentPurchaseSettings
          }
          footer {
            ...FragmentFooter
          }
          header {
            ...FragmentHeader
          }
          brandSettings {
            ...FragmentBrandSettingsComposition
          }
          singupFormBaner {
            ...FragmentSignUpBanner
          }
          siteSettings {
            ...FragmentSiteSettingsComposition
          }
          waiSettings {
            skipText
          }
          warning {
            ...FragmentWarning
          }
          signUpPopup {
            ...FragmentSignUpPopup
          }
          signUpFormPopup {
            ...FragmentSignUpFormPopup
          }
          promoPlatformForm {
            ...FragmentPromoPlatformForm
          }
        }
        itemsLabel
        topMessage
        subtotalLabel
        shippingLabel
        disclaimer
        helpLabel
        emptyCartIconMessage {
          properties {
            description
            icon
            iconAlignment
          }
        }
        freeShippingLabel
        linkContact {
          properties {
            ariaLabel
            link {
              name
              target
              url
            }
          }
        }
        linkCheckout {
          properties {
            ariaLabel
            link {
              name
              target
            }
          }
        }
        cards {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 150) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
          }
        }
        shopifyLogo {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 150) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
          }
        }
        secureLabel
        body {
          ... on TSignupPlaceholder {
            ...FragmentSignUp
          }
          ... on TImageTextGrid {
            ...FragmenTImageTextGrid
          }
        }
      }
    }
    allProduct {
      nodes {
        skuId
        shopifyId
        images {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
          }
        }
        productInfo
        link
        productSize
        packagingType
        productScent
        title
        shortDescription
      }
    }
    allBundle {
      nodes {
        productInfo
        images {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
          }
        }
        bundleProducts {
          link
          quantity
        }
        title
        shortDescription
        shopifyId
        skuId
        link
      }
    }
  }
`;

export default CartPage;
