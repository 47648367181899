import React, { FC } from 'react';
import { ICheckoutItem } from './model';

const CheckoutItem: FC<ICheckoutItem> = ({
  itemLabel,
  itemPrice,
  currencieLabel,
  alternativeLabel,
  oldPrice,
}) => {
  const itemPriceInfo = itemPrice ? `${currencieLabel}${itemPrice}` : alternativeLabel;

  return itemLabel ? (
    <li className="checkout__prices-item">
      <span className="prices-item-label">{itemLabel}</span>
      <span className="prices-item__prices">
        <span className="prices__old-pice">{!!oldPrice && `${currencieLabel}${oldPrice}`}</span>
        <span className="prices__new-pice">{itemPriceInfo}</span>
      </span>
    </li>
  ) : null;
};

export default CheckoutItem;
