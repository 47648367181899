import React, { FC } from 'react';

import IconCustom from 'common/IconCustom';
import { DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';
import { CartEmptyProps } from './models';

const CartEmpty: FC<CartEmptyProps> = ({ emptyCartIconMessage, continueShoppingPath }) => (
  <div className="cart-page__cart-empty" data-testid="art-page__cart-empty">
    <IconCustom
      classes="cart-empty__icon"
      icon={emptyCartIconMessage?.[0]?.properties?.icon?.[0]}
    />
    <div className="cart-empty__description">
      <DangerouslySetInnerHtml html={emptyCartIconMessage?.[0]?.properties?.description} />
    </div>
    <Button
      to={continueShoppingPath?.[0]?.properties?.link?.[0]?.url}
      classes=""
      variant="secondary"
    >
      {continueShoppingPath?.[0]?.properties?.link?.[0]?.name}
    </Button>
  </div>
);

export default CartEmpty;
