import React, { FC, useContext } from 'react';

import { Link } from 'gatsby';
import Button from 'common/Button';
import { CartContext } from 'context/CartContextProvider/CartContextProvider';
import { CartItemDeletedProps } from './models';

const CartItemDeleted: FC<CartItemDeletedProps> = ({
  deletedItemPlaceholder,
  addDeletedItemTitle,
  handleAddProduct,
  link,
  title,
  variantId,
  quantity,
}) => {
  const { isLoading } = useContext(CartContext);
  const deletedItemStringStart = deletedItemPlaceholder.slice(
    0,
    deletedItemPlaceholder.indexOf('%item%')
  );
  const deletedItemStringEnd = deletedItemPlaceholder.slice(
    deletedItemPlaceholder.indexOf('%item%') + 6
  );

  return (
    <div className="cart-item cart-item--deleted" data-testid="cart-item--deleted">
      <div className="deleted-item__text">
        <span className="text__start">{deletedItemStringStart}</span>
        <Link className="text__link" to={link}>
          {title}
        </Link>
        <span className="text__end">{deletedItemStringEnd}</span>
      </div>
      <Button
        disabled={isLoading}
        clickHandler={() => handleAddProduct(variantId, quantity)}
        variant="secondary-link"
        classes="deleted-item__undo-button"
      >
        {addDeletedItemTitle}
      </Button>
    </div>
  );
};

export default CartItemDeleted;
