import React, { FC, useContext, useMemo, useState } from 'react';
import { CartContext } from 'context/CartContextProvider/CartContextProvider';
import CartEmpty from 'components/CartEmpty';
import './Cart.scss';
import Checkout from 'components/Checkout';
import CartWithItems from 'components/CartWithItems';
import useShopifyHelper from 'hooks/useShopifyHelper';
import { ICart } from './model';

const Cart: FC<ICart> = ({
  isShopify,
  lang,
  emptyCartIconMessage,
  cartPath,
  cartProductsData,
  freeShippingLabel,
  itemsLabel,
  shippingLabel,
  secureLabel,
  subtotalLabel,
  shopifyLogo,
  disclaimer,
  helpLabel,
  cards,
  linkContact,
  linkCheckout,
}) => {
  const {
    removeLineItems,
    updateProductInCheckout,
    totalPrice,
    addProductToCheckout,
    cartItemsQuantity,
    isLoading,
    purchaseSettings,
  } = useContext(CartContext);
  const {
    addDeletedItemTitle,
    continueShoppingPath,
    cartQuantityLabel,
    cartPriceLabel,
    cartRemoveLabel,
    deletedItemPlaceholder,
    quantitySelectorAriaLabel,
  } = purchaseSettings;
  const [deletedItems, setDeletedItems] = useState([]);

  const { boughtProducts } = useShopifyHelper({
    isShopify,
    lang,
    cartProductsData,
  });

  const handleAddProduct = (variantId: string, quantity: number) => {
    addProductToCheckout(variantId, quantity).then(() => {
      if (deletedItems.length > 0) {
        const newDeletedItems = deletedItems.filter((item) => item.variantId !== variantId);
        setDeletedItems(newDeletedItems);
      }
    });
  };

  const handleRemoveItem = (productId: string) => {
    removeLineItems(productId).then(() => {
      const newDeletedItem = boughtProducts?.find((item) => item.productId === productId);
      setDeletedItems([
        ...deletedItems,
        {
          ...newDeletedItem,
          isDeleted: true,
        },
      ]);
    });
  };

  const cartProducts = useMemo(
    () => [...boughtProducts, ...deletedItems],
    [JSON.stringify(deletedItems), JSON.stringify(boughtProducts)]
  );

  return (
    <div className="cart-page">
      {cartProducts?.length > 0 ? (
        <div className="cart-page__grid">
          <div className="cart-page__card">
            <CartWithItems
              cartProducts={cartProducts}
              totalPrice={totalPrice}
              addDeletedItemTitle={addDeletedItemTitle}
              handleAddProduct={handleAddProduct}
              deletedItemPlaceholder={deletedItemPlaceholder}
              updateProductInCheckout={updateProductInCheckout}
              handleRemoveItem={handleRemoveItem}
              continueShoppingPath={continueShoppingPath}
              cartQuantityLabel={cartQuantityLabel}
              cartPriceLabel={cartPriceLabel}
              cartRemoveLabel={cartRemoveLabel}
              cartItemsQuantity={cartItemsQuantity}
              cartPath={cartPath}
              quantitySelectorAriaLabel={quantitySelectorAriaLabel}
            />
          </div>
          <Checkout
            freeShippingLabel={freeShippingLabel}
            itemsLabel={itemsLabel}
            shippingLabel={shippingLabel}
            secureLabel={secureLabel}
            subtotalLabel={subtotalLabel}
            shopifyLogo={shopifyLogo}
            disclaimer={disclaimer}
            helpLabel={helpLabel}
            cards={cards}
            linkContact={linkContact}
            linkCheckout={linkCheckout}
          />
        </div>
      ) : null}
      {cartProducts?.length === 0 && !isLoading ? (
        <CartEmpty
          emptyCartIconMessage={emptyCartIconMessage}
          continueShoppingPath={continueShoppingPath}
        />
      ) : null}
    </div>
  );
};

export default Cart;
