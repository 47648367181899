import React, { FC } from 'react';
import CartItemDeleted from 'components/CartItemDeleted';
import CartItemInCart from 'components/CartItemInCart';
import Button from 'common/Button';
import { ICartWithItemsProps } from './models';

const CartWithItems: FC<ICartWithItemsProps> = ({
  cartProducts,
  addDeletedItemTitle,
  continueShoppingPath,
  cartQuantityLabel,
  cartPriceLabel,
  cartRemoveLabel,
  deletedItemPlaceholder,
  updateProductInCheckout,
  handleAddProduct,
  handleRemoveItem,
  quantitySelectorAriaLabel,
}) => (
  <div className="cart" data-testid="cart">
    <div className="cart__items">
      {cartProducts.map((cartProduct) => {
        const {
          availableQuantity,
          price,
          productInfo,
          image,
          quantity,
          oldPrice,
          title,
          description,
          productId,
          link,
          variantId,
          isDeleted,
        } = cartProduct;

        return (
          <React.Fragment key={productId}>
            {quantity && !isDeleted ? (
              <CartItemInCart
                key={price}
                productInfo={productInfo}
                image={image}
                quantity={quantity}
                oldPrice={oldPrice}
                price={price}
                title={title}
                description={description}
                productId={productId}
                availableQuantity={availableQuantity}
                updateProductInCheckout={updateProductInCheckout}
                handleRemoveItem={handleRemoveItem}
                cartQuantityLabel={cartQuantityLabel}
                cartPriceLabel={cartPriceLabel}
                cartRemoveLabel={cartRemoveLabel}
                link={link}
                quantitySelectorAriaLabel={quantitySelectorAriaLabel}
              />
            ) : (
              <CartItemDeleted
                handleAddProduct={handleAddProduct}
                variantId={variantId}
                deletedItemPlaceholder={deletedItemPlaceholder}
                addDeletedItemTitle={addDeletedItemTitle}
                link={link}
                title={title}
                quantity={quantity}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
    <div className="control-buttons">
      {continueShoppingPath ? (
        <Button
          to={continueShoppingPath?.[0]?.properties?.link?.[0]?.url}
          classes="control-buttons__continue"
          variant="secondary"
        >
          {continueShoppingPath?.[0]?.properties?.link?.[0]?.name}
        </Button>
      ) : null}
    </div>
  </div>
);

export default CartWithItems;
